import React from "react";
import Topbar from "../components/Topbar/Topbar";
import { Box, Container, Stack, Typography } from "@mui/material";
import Footer from "../components/footer/Footer";
export default function Roles() {
  const container = {
    backgroundColor: "white",
    marginTop: "20px",
    textAlign: "justify",
    padding: "18px 0 ",
    borderRadius: "20px",
    marginBottom: "30px",
  };
  return (
    <Box>
      <Topbar />
      <Box sx={{ backgroundColor: "#E4E4E6", display: "flex" }}>
        <Container maxWidth="md" sx={container}>
          <Stack spacing={3}>
            <Typography
              variant="h4"
              sx={{ textAlign: "right", fontWeight: "700" }}>
              شرایط و قوانین سبزلرن
            </Typography>
            <hr />
            <Typography sx={{ textAlign: "right" }}>
              کاربر گرامی لطفاً موارد زیر را جهت استفاده بهینه از خدمات و
              برنامه‌‏های کاربردی آکادمی سبز لرن با دقت ملاحظه فرمایید.ورود
              کاربران به وب‏‌سایت آکادمی سبز لرن هنگام استفاده از پروفایل شخصی،
              طرح‏‌های تشویقی، ویدئوهای رسانه تصویری آکادمی سبز لرن و سایر خدمات
              ارائه شده توسط آکادمی سبز لرن به معنای آگاه بودن و پذیرفتن شرایط و
              قوانین و همچنین نحوه استفاده از سرویس‌‏ها و خدمات آکادمی سبز لرن
              است. توجه داشته باشید که ثبت سفارش نیز در هر زمان به معنی پذیرفتن
              کامل کلیه شرایط و قوانین آکادمی سبز لرن از سوی کاربر است. لازم به
              ذکر است شرایط و قوانین مندرج، جایگزین کلیه توافق‏‌های قبلی محسوب
              می‏‌شود.
            </Typography>
            <Typography
              variant="h5"
              sx={{
                paddingTop: "100px",
                textAlign: "right",
                fontWeight: "600",
              }}>
              شرایط عودت وجه به دانشجو
            </Typography>
            <Typography sx={{ textAlign: "right" }}>
              از آنجایی که سبزلرن یک اکادمی خصوصی در زمینه آموزش برنامه نویسی و
              شبکه و امنیت است. تلاش میکند تا با مدرسین خود. طبق یک قرارداد رسمی
              و امضا شده بین طرفین. دوره های خود را بر روی این پلتفرم منتشر کند!
              مدرسینی که در سبزلرن مشغول فعالیت هستند. افرادی با تجربه و باران
              دیده به حساب می آیند. چرا که سبزلرن با مدرسینی همکاری میکند که
              حداقل ۲ سال تجربه مفید و تجربه تدریس قبلی را داشته باشند. لذا اگر
              مدرسی بر خلاف قرارداد طرفین پیش رود و برای دوره خود پشتیبانی ارائه
              ندهد. سبزلرن موظف است که در اولین فرصت. مدرس دوره را تغییر دهد و
              اگر قرار باشد پروسه تغییر مدرس دوره کمی طول بکشد. سبزلرن موظف است
              که کمک پشتیبان هایی که در آن زمینه تخصص دارند را استخدام کند تا
              دانشجو در پروسه یادگیری. دچار اختلال نشود ! و از سمت و سویی دیگر.
              سبزلرن حداقل ۱۰ الی ۳۰ درصد محتوای دوره های خود را جهت ارزیابی
              بهتر رایگان میکند تا دانشجو بتواند با ارزیابی بهتر در دوره مورد
              نظر ثبت نام کند. اگر چنانچه دانشجو. پس از یک هفته و یا ۲ ماه و….
              با دلایلی همچون من به این پول نیاز دارم و مشکل مالی دارم من در
              فلانجا قرار شد به عنوان کار اموز پذیرفته شوم و آنها قرار است به من
              مهارت جدیدی بیاموزند من از این دوره خوشم نمی آید و میخواهم پولم را
              پس بگیرم من تغییر حوزه داده ام و نیازی به این آموزش ندارم و… از
              این قبیل مراجعه کند. مجموعه سبزلرن موظف نیست که مبلغ را به دانشجو
              عودت دهد. چرا که از قبل از طریق بخش نظرات و رایگان کردن بخش قابل
              توجهی از دوره. بستری برای برسی بهتر را فراهم کرده است و مسئولیت
              تغییر شرایط کاربر. طبق عرف به عهده خودش میباشد
            </Typography>
          </Stack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
