const Products = [
  {
    id: 1,
    name: "فرانت اند",
    auther: "محمد امین سعیدی راد",
    title: "آموزش بوتسرپ - جدیدترین نسخه",
    describtion: "خوش آمدید به بهترین آموزش بوت استرپ ورژن 5 در ایران ",
    src: "image/bootstrap.jpg",
    price: "450.000 هزار تومان",
  },
  {
    id: 2,
    name: "هوش مصنوعی",
    auther: "قدیر یلمه",
    title: "هوش مصنوعی در برنامه نویسی",
    describtion: "هوش مصنوعی یک حوزه از علوم کامپیوتر است که به طراحی و  ",
    src: "image/gpt.png",
    price: "850.000 هزار تومان",
  },
  {
    id: 3,
    name: "بک اند",
    auther: "محمد امین سعیدی راد",
    title: "آموزش نود جی اس بدون پیش نیاز",
    describtion: "نود جی اس بازدهی و انعطاف بالایی دارد. کراس پلتفرم است",
    src: "image/nodejs.jpg",
    price: "3.150.000 هزار تومان",
  },
  {
    id: 4,
    name: " بک اند",
    auther: "رضا دولتی",
    title: "پروژه های کاربردی با پایتون",
    describtion:
      "پایتون یک زبان برنامه نویسی عمومی و Open Source است که می تواند",
    src: "image/phyton.png",
    price: "150.000 هزار تومان",
  },
  {
    id: 5,
    name: "فرانت اند",
    auther: "محمد امین سعیدی راد",
    title: "پروژه محور PWA آموزش ",
    describtion:
      "..تکنولوژِی تقریبا جدیده که میتونیم بهمون اجازه میده وب‌سایتی که ",
    src: "image/PWA.jpg",
    price: "1.100.000 هزار تومان",
  },
  {
    id: 6,
    name: "فرانت اند",
    auther: "محمد امین سعیدی راد",
    title: "اموزش تکست  پروژه محور",
    describtion: "نکست یه فریمورک مبتنی بر ری‌اکت هست که امروزه تو بازار",
    src: "image/sabz.png",
    price: "4.000.000 هزار تومان",
  },
];

export default Products;
